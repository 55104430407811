// pages/Socials.js
import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import '../App.css';
import 'fomantic-ui-css/semantic.min.css';
import { isMobile } from 'react-device-detect';
import WordPressSocials from '../components/WordPressSocials/WordPressSocials';
import {
  GridRow,
  GridColumn,
  Grid,
  Breadcrumb,
  Image,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../App.css';

const colors = [
  'red',
]

const Socials = () => {
  const [siteTitle, setSiteTitle] = useState('');

  useEffect(() => {
    document.title = siteTitle || 'Latin Dance Canada';
  }, [siteTitle]);

  useEffect(() => {
    const fetchSiteTitle = async () => {
      try {
        const response = await axios.get('https://latindancecanada.com/wp-json/');
        setSiteTitle(response.data.name);
      } catch (error) {
        console.error('Error fetching site title:', error);
      }
    };

    fetchSiteTitle();
  }, []);

  return (
    <Layout>
       {isMobile ? (
        <></>
      ) : (
        <section className="hero">
          <Image src="assets/ldc-bg-2022.webp" fluid />
        </section>
      )}
      <section className="App-breadcrumb">
        <Grid padded>
          {colors.map((color) => (
            <GridRow color={color} key={color}>
              <GridColumn>
                <Breadcrumb className="home-link" as={Link} to="/">Home</Breadcrumb>
              </GridColumn>
            </GridRow>
          ))}
        </Grid>
      </section>
      <section className="ui container">
        <WordPressSocials />
      </section>
    </Layout>
  );
};

export default Socials;