// pages/Socials.js
import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import WordPressClasses from '../components/WordPressClasses/WordPressClasses';
import {
  GridRow,
  GridColumn,
  Grid,
  BreadcrumbSection,
  BreadcrumbDivider,
  Image,
  Breadcrumb
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import '../App.css';

const colors = ['red'];

const Classes = () => {
  const [siteTitle, setSiteTitle] = useState('');

  useEffect(() => {
    document.title = siteTitle || 'Latin Dance Canada';
  }, [siteTitle]);

  useEffect(() => {
    const fetchSiteTitle = async () => {
      try {
        const response = await axios.get('https://latindancecanada.com/wp-json/');
        setSiteTitle(response.data.name);
      } catch (error) {
        console.error('Error fetching site title:', error);
      }
    };

    fetchSiteTitle();
  }, []);

  return (
    <Layout>
      {isMobile ? (
        <></>
      ) : (
        <section className="hero">
          <Image src="assets/ldc-bg-2022.webp" fluid />
        </section>
      )}
      {!isMobile && (
        <section className="App-breadcrumb">
          <Grid padded>
            {colors.map((color) => (
              <GridRow color={color} key={color}>
                <GridColumn>
                  <Breadcrumb>
                    <BreadcrumbSection as={Link} to="/">Home</BreadcrumbSection>
                    <BreadcrumbDivider />
                    <BreadcrumbSection active>
                      Classes
                    </BreadcrumbSection>
                  </Breadcrumb>
                </GridColumn>
              </GridRow>
            ))}
          </Grid>
        </section>
      )}
      <section className="ui container class-dates">
        <WordPressClasses />
      </section>
    </Layout>
  );
};

export default Classes;