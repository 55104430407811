// Update your imports in index.js or App.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './pages/App';
import Socials from './pages/Socials';
import Classes from './pages/Classes';
import Salsa1 from './pages/Salsa1';
import Salsa2 from './pages/Salsa2';
import Bachata1 from './pages/Bachata1';
import Bachata2 from './pages/Bachata2';
import ThankYouClasses from './pages/ThankYouClasses';
import ThankYouSocials from './pages/ThankYouSocials';
import Contact from './pages/Contact';
import Conduct from './pages/Conduct';
import Family from './pages/Family';
import PageLinks from './pages/More';

ReactDOM.render(
  <Router>
    <Routes> {/* Replace <Switch> with <Routes> */}
      <Route path="/" element={<App />} />
      <Route path="/socials" element={<Socials />} />
      <Route path="/classes" element={<Classes />} />
      <Route path="/classes/salsa-1" element={<Salsa1 />} />
      <Route path="/classes/salsa-2" element={<Salsa2 />} />
      <Route path="/classes/bachata-1" element={<Bachata1 />} />
      <Route path="/classes/bachata-2" element={<Bachata2 />} />
      <Route path="/thankyou-classes" element={<ThankYouClasses />} />
      <Route path="/thankyou-socials" element={<ThankYouSocials />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/conduct" element={<Conduct />} />
      <Route path="/our-family" element={<Family />} />
      <Route path="/pages" element={<PageLinks />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);
