import React from 'react';
import 'fomantic-ui-css/semantic.min.css';
import Layout from '../Layout';
import WordPressSocials from '../components/WordPressSocials/WordPressSocials';
import {
  Image
} from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';

function App() {
  return (
    <Layout regularPage={true}>
        {!isMobile && (
          <section className="hero">
            <Image src="assets/code-of-conduct.webp" fluid />
          </section>
        )}
        <section className="ui container home social-dates">
          <WordPressSocials />
        </section>
    </Layout>
  );
}

export default App;
