import React from 'react';
import '../App.css';
import 'fomantic-ui-css/semantic.min.css';
import Layout from '../Layout';
import {
  GridRow,
  GridColumn,
  Grid,
  Image,
  Segment,
  List,
  Header
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

function More() {
  return (
    <Layout regularPage={true}>
      {isMobile ? (
        <></>
      ) : (
        <section className="hero">
          <Image src="../assets/ldc-bg-2022.webp" fluid />
        </section>
      )}
      <section className="ui container additional-pages">
        <Grid className="section">
          <GridRow>
            <GridColumn mobile={16} tablet={16} computer={16}>
              <Segment vertical>
                <Header as='h2'>
                  About
                </Header>
                <List link>
                  <List.Item as={Link} to="/our-family">Our Family</List.Item>
                </List>
              </Segment>
              <Segment vertical>
                <Header as='h2'>
                  Support
                </Header>
                <List link>
                  <List.Item as={Link} to="/contact">Contact</List.Item>
                  <List.Item as={Link} to="/conduct">Conduct</List.Item>
                </List>
              </Segment>
            </GridColumn>
          </GridRow>
        </Grid>
      </section>
    </Layout>
  );
}

export default More;