import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import NavigationBottom from './components/NavigationBottom';
import { isMobile } from 'react-device-detect';

const Layout = ({ children, regularPage }) => {
  return (
    <div className={`App ${regularPage ? 'page' : ''} ${isMobile ? 'mobile' : ''}`}>
      <Header regularPage={regularPage} />
      <main className="main">{children}</main>
      {isMobile ? <NavigationBottom /> : <Footer />}
    </div>
  );
};

export default Layout;
