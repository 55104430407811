import React from 'react';
import { IoIosArrowForward } from "react-icons/io";
import '../App.css';
import 'fomantic-ui-css/semantic.min.css';
import Layout from '../Layout';
import {
  Header,
  GridRow,
  GridColumn,
  Grid,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
  List,
  MessageHeader,
  Message,
  Divider,
  Image
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const colors = ['red'];

function Conduct() {
  return (
    <Layout>
      {isMobile ? (
        <></>
      ) : (
        <section className="hero">
          <Image src="../assets/ldc-bg-2022.webp" fluid />
        </section>
      )}
      {!isMobile && (
        <section className="App-breadcrumb">
          <Grid padded>
            {colors.map((color) => (
              <GridRow color={color} key={color}>
                <GridColumn>
                  <Breadcrumb>
                    <BreadcrumbSection as={Link} to="/">Home</BreadcrumbSection>
                    <BreadcrumbDivider />
                    <BreadcrumbSection active>
                      Conduct
                    </BreadcrumbSection>
                  </Breadcrumb>
                </GridColumn>
              </GridRow>
            ))}
          </Grid>
        </section>
      )}
      <section className="ui container conduct">
        <Grid className="section">
          {isMobile ? (
            <></>
          ) : (
            <GridRow>
              <GridColumn mobile={16} tablet={16} computer={16}>
                <Header size='large'>Conduct</Header>
              </GridColumn>
            </GridRow>
          )}
          <GridRow>
            <GridColumn mobile={16} tablet={16} computer={16}>
              <Message warning size='small'>
                <MessageHeader>Important</MessageHeader>
                <p>Our community events are meant to be a place for everyone to make friends, learn skills, socialize, have FUN and FEEL SAFE. Come talk to the main event organizer or any one of the volunteers if you have any questions, comments or concerns. We’re always here to help.</p>
              </Message>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn mobile={16} tablet={8} computer={8} className="col">
              <List>
                <List.Item>
                  <List.Content>
                    <IoIosArrowForward style={{ marginRight: '10px', color: 'gray' }} />
                    We will NOT tolerate harassment or discrimination of any kind. Everyone is welcome regardless of race, age, sexuality, appearance, religion, or ability.
                  </List.Content>
                </List.Item>
                <Divider />
                <List.Item>
                  <List.Content>
                    <IoIosArrowForward style={{ marginRight: '10px', color: 'gray' }} />
                    Always dance to the level of your partner and make them feel good about themselves and dancing with you. This is especially important for people who are NEW to the dance scene.</List.Content>
                </List.Item>
                <Divider />
                <List.Item>
                  <List.Content>
                    <IoIosArrowForward style={{ marginRight: '10px', color: 'gray' }} />
                    Everyone is ALLOWED to decline a dance, and anyone can ask anyone to dance.</List.Content>
                </List.Item>
                <Divider />
                <List.Item>
                  <List.Content>
                    <IoIosArrowForward style={{ marginRight: '10px', color: 'gray' }} />
                    PRACTISE good hygiene, frequent hand washing, fresh clothes or a sweat towel.</List.Content>
                </List.Item>
                <Divider />
              </List>
            </GridColumn>
            <GridColumn mobile={16} tablet={8} computer={8} className="col">
              <List>
              <List.Item>
                  <List.Content>
                    <IoIosArrowForward style={{ marginRight: '10px', color: 'gray' }} />
                    Please keep your dance partner SAFE on the dance floor; BE AWARE of your surroundings; don’t do dips or tricks that could hurt your partner; always ASK for permission first.</List.Content>
                </List.Item>
                <Divider />
                <List.Item>
                  <List.Content>
                    <IoIosArrowForward style={{ marginRight: '10px', color: 'gray' }} />
                    Anyone can end a dance at any time if you are uncomfortable or the person is disrespecting your boundaries.</List.Content>
                </List.Item>
                <Divider />
                <List.Item>
                  <List.Content>
                    <IoIosArrowForward style={{ marginRight: '10px', color: 'gray' }} />
                    It is NOT appropriate to critique or instruct your partner while social dancing unless it’s been established that feedback between you two is welcome.</List.Content>
                </List.Item>
                <Divider />
                <List.Item>
                  <List.Content>
                    <IoIosArrowForward style={{ marginRight: '10px', color: 'gray' }} />
                    THANK your dance partner after a dance, be respectful and courteous, and have FUN! Gratitude goes a long way when you express it authentically every time.</List.Content>
                </List.Item>
                <Divider />
              </List>
            </GridColumn>
          </GridRow>
        </Grid>
      </section>
    </Layout>
  );
  
}

export default Conduct;
