import React, { useState, useEffect } from 'react';
import { Container, Menu, MenuItem, Icon, Image } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../ldc-logo.svg';
import '../App.css';
import { isMobile } from 'react-device-detect';

const Navigation = () => {
  const [activeItem, setActiveItem] = useState(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showMobileIcon, setShowMobileIcon] = useState(false);
  //const [isMobileView, setIsMobileView] = useState(isMobile); // Initialize isMobileView state
  const location = useLocation();

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    setMobileMenuOpen(false); // Close the mobile menu when an item is clicked
  };

  const handleToggleMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      //const isMobile = window.innerWidth < 768;
      setShowMobileIcon(window.innerWidth < 450);
      //setIsMobileView(isMobile); // Update isMobileView state
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check on mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Removed setIsMobileView from dependency array

  const getMenuItemHeader = () => {
    if (location.pathname === '/') {
      return 'Socials';
    } else if (location.pathname === '/classes') {
      return 'Classes';
    } else if (location.pathname === '/classes/bachata-1') {
      return 'Bachata Classes';
    } else if (location.pathname === '/classes/bachata-2') {
      return 'Bachata Classes';
    } else if (location.pathname === '/classes/salsa-1') {
      return 'Salsa Classes';
    } else if (location.pathname === '/classes/salsa-2') {
      return 'Salsa Classes';
    } else if (location.pathname === '/pages') {
      return 'More';
    } else if (location.pathname === '/contact') {
      return 'Contact';
    } else if (location.pathname === '/our-family') {
      return 'Our Family';
    } else if (location.pathname === '/conduct') {
      return 'Conduct';
    }
    return null;
  };

  return (
    <Container fluid>
      <Menu size='small' className={`primary ${isMobile ? 'fixed red' : ''}`}>
        {!isMobile && (
          <Menu.Item as={Link} to='/'>
            <Image src={logo} className="App-logo" alt="logo" size="small" />
          </Menu.Item>
        )}

        {/* Upcoming Socials for Mobile, Hidden on Desktop */}
        {isMobile && getMenuItemHeader() && (
          <Menu.Menu>
            <MenuItem header className='main-header'>{getMenuItemHeader()}</MenuItem>
          </Menu.Menu>
        )}

        {/* Right-Positioned Menu (Hidden on Mobile) */}
        {!isMobile && !showMobileIcon && (
          <Menu.Menu position='right'>
            <MenuItem
              as={Link} // Use Link instead of MenuItem
              to='/' // Specify the route you want to link to
              name='Socials'
              active={activeItem === 'socials'}
              onClick={handleItemClick}
            />
            <MenuItem
              as={Link} // Use Link instead of MenuItem
              to='/classes' // Specify the route you want to link to
              name='Classes'
              active={activeItem === 'classes'}
              onClick={handleItemClick}
            />
          </Menu.Menu>
        )}

        {/* Mobile Menu Icon (Visible in Mobile and below 450px) */}
        {!isMobile && (showMobileIcon || isMobileMenuOpen) && (
          <Menu.Menu position='right'>
            <MenuItem>
              <Icon name='bars' size='large' onClick={handleToggleMenu} />
            </MenuItem>
          </Menu.Menu>
        )}
      </Menu>

      {/* Mobile Menu Items */}
      {isMobileMenuOpen && (
        <Menu size='small' stackable>
          <MenuItem
              as={Link} // Use Link instead of MenuItem
              to='/classes' // Specify the route you want to link to
              name='Classes'
              active={activeItem === 'classes'}
              onClick={handleItemClick}
            />
        </Menu>
      )}
    </Container>
  );
};

export default Navigation;