import React, { useEffect, useState } from 'react';
import 'fomantic-ui-css/semantic.min.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Header,
  Button,
  Divider,
  Grid,
  GridRow,
  GridColumn,
  MenuItem,
  Menu,
  Loader
} from 'semantic-ui-react';

const WordPressClasses = () => {
  const [classRegistrationOptions, setClassRegistrationOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeFilter, setActiveFilter] = useState(null); // Initialize active filter state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const classRegistrationResponse = await axios.get('https://latindancecanada.com/wp-json/custom/v2/get_options');
        setClassRegistrationOptions(classRegistrationResponse.data);
      } catch (error) {
        console.error('Error fetching class registration data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getClassLink = (title) => {
    switch (title) {
      case 'Salsa Newbies':
        return '/classes/salsa-1';
      case 'Salsa Improvers':
        return '/classes/salsa-2';
      case 'Bachata Newbies':
        return '/classes/bachata-1';
      case 'Bachata Improvers':
        return '/classes/bachata-2';
      default:
        return '/';
    }
  };

  const handleFilterClick = (filter) => {
    if (filter === 'all') {
      setActiveFilter(null); // Reset active filter to show all classes
    } else {
      setActiveFilter(filter);
    }
  };

  const renderAccordionItems = () => {
    let filteredItems = classRegistrationOptions.class_reg_accordion_items;

    if (activeFilter && activeFilter !== 'all') {
      filteredItems = filteredItems.filter(item => {
        if (activeFilter === 'salsa') {
          return item.class_reg_custom_accordion_title.toLowerCase().includes('salsa');
        } else if (activeFilter === 'bachata') {
          return item.class_reg_custom_accordion_title.toLowerCase().includes('bachata');
        }
        return true; // Ensure a value is always returned
      });
    }

    if (!filteredItems || filteredItems.length === 0) {
      return (
        <Grid>
          <GridRow>
            <GridColumn mobile={16} tablet={16} computer={16}>
              <p>No scheduled classes coming up. Check back later.</p>
            </GridColumn>
          </GridRow>
        </Grid>
      );
    }

    return filteredItems.map((item, index) => {
      const isLastItem = index === filteredItems.length - 1;
      return (
        <div key={item.order} className="section-item classes">
          <Header size='medium'>{item.class_reg_custom_accordion_title}</Header>
          <Link to={getClassLink(item.class_reg_custom_accordion_title)}>
            <Button className="big" fluid color='green'>See class information</Button>
          </Link>
          {isLastItem ? null : <Divider />}
        </div>
      );
    });
  };

  return (
    <div className="class-registration">
      {isLoading ? (
        <>
          <Divider hidden />
          <Loader active inline='centered' />
          <Divider hidden />
        </>
      ) : (
        <>
          <div className="class-filter">
            <Menu text>
              <MenuItem header>Filter</MenuItem>
              <MenuItem
                name='all'
                active={activeFilter === null || activeFilter === 'all'}
                onClick={() => handleFilterClick('all')} // Use handleFilterClick for filter event
              />
              <MenuItem
                name='salsa'
                active={activeFilter === 'salsa'}
                onClick={() => handleFilterClick('salsa')} // Use handleFilterClick for filter event
              />
              <MenuItem
                name='bachata'
                active={activeFilter === 'bachata'}
                onClick={() => handleFilterClick('bachata')} // Use handleFilterClick for filter event
              />
            </Menu>
          </div>
          {renderAccordionItems()}
        </>
      )}
    </div>
  );
};

export default WordPressClasses;