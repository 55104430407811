import React, { useState } from 'react';
import '../App.css';
import 'fomantic-ui-css/semantic.min.css';
import Layout from '../Layout';
import {
  Header,
  GridRow,
  GridColumn,
  Grid,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
  Button,
  Message,
  FormField,
  Form,
  FormTextArea,
  Image
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const colors = ['red'];

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const formData = {
      'form-name': 'contact',
      name,
      email,
      message
    };

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(formData),
    })
    .then(() => {
      console.log("Form successfully submitted");
      setSubmissionStatus("success"); // Set submission status to success
    })
    .catch((error) => {
      console.error("Form submission error:", error);
      setSubmissionStatus("error"); // Set submission status to error
    });
  };

  return (
    <Layout>
      {isMobile ? (
        <></>
      ) : (
        <section className="hero">
          <Image src="assets/ldc-bg-2022.webp" fluid />
        </section>
      )}
      {!isMobile && (
        <section className="App-breadcrumb">
          <Grid padded>
            {colors.map((color) => (
              <GridRow color={color} key={color}>
                <GridColumn>
                  <Breadcrumb>
                    <BreadcrumbSection as={Link} to="/">Home</BreadcrumbSection>
                    <BreadcrumbDivider />
                    <BreadcrumbSection active>
                      Contact
                    </BreadcrumbSection>
                  </Breadcrumb>
                </GridColumn>
              </GridRow>
            ))}
          </Grid>
        </section>
      )}
      <section className="ui container contact">
        <Grid className="section">
          {isMobile ? (
            <></>
          ) : (
            <GridRow>
              <GridColumn mobile={16} tablet={16} computer={16}>
                <Header size='large'>Contact</Header>
              </GridColumn>
            </GridRow>
          )}
          <GridRow>
            <GridColumn mobile={16} tablet={8} computer={8}>
              <Header size='medium'>WE WELCOME FEEDBACK, REVIEWS, OR JUST PLAIN HELLOS!</Header>
              <p>We are committed to you having fun and dancing whether you are a beginner or more experienced. If you have any questions, feel free to reach out to us.</p>

              {/* Conditionally render the form based on submissionStatus */}
              {submissionStatus !== "success" && (
                <Form name="contact" method="POST" data-netlify="true" onSubmit={handleSubmit}>
                  <input type="hidden" name="subject" data-remove-prefix
  value="New Message at Latin Dance canada" />
                <FormField>
                  <label>Your Name</label>
                  <input placeholder='Your Name' name='name' value={name} onChange={e => setName(e.target.value)} />
                </FormField>
                <FormField>
                  <label>Your Email</label>
                  <input placeholder='Your Email' name="email" value={email} onChange={e => setEmail(e.target.value)} />
                </FormField>
                <FormField>
                  <FormTextArea label='Message' placeholder='Tell us more about you...' value={message} onChange={e => setMessage(e.target.value)} />
                </FormField>
                <Button fluid size='big' color='green' type='submit'>Submit Now</Button>
              </Form>
              )}
              {/* Display success message if submissionStatus is "success" */}
              {submissionStatus === "success" && (
                <Message positive>
                  <Message.Header>Form Submitted</Message.Header>
                  <p>Your message has been successfully submitted.</p>
                </Message>
              )}
              {/* Display error message if submissionStatus is "error" */}
              {submissionStatus === "error" && (
                <Message negative>
                  <Message.Header>Form Submission Error</Message.Header>
                  <p>There was an error submitting your message. Please try again later.</p>
                </Message>
              )}
            </GridColumn>
            <GridColumn mobile={16} tablet={8} computer={8} className="google-review">
              <Header size='medium'>If you have a minute to spare</Header>
                <p>We would really appreciate a Google review if you had a great experience at one of our events.</p>
                <a href="https://search.google.com/local/writereview?placeid=ChIJczBk7DZzj1QRPFrBalfWG7g" target="_blank" rel="noopener noreferrer">
                  <Button fluid size='big' color='black'>
                    Leave us a Google review
                  </Button>
                </a>
            </GridColumn>
          </GridRow>
        </Grid>
      </section>
    </Layout>
  );
}

export default Contact;
