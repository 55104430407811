import React, { useState } from 'react';
import { Menu, MenuItem, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import '../App.css';

const Navigation = () => {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  return (
    
      <Menu className="bottom-menu" compact fixed="bottom" widths={3} icon="labeled">
        <MenuItem
          as={Link}
          to="/"
          name='music'
          active={activeItem === 'music'}
          onClick={handleItemClick}
        >
          <Icon name='music' size='tiny' />
          Socials
        </MenuItem>

        <MenuItem
          as={Link}
          to="/classes"
          name='graduation'
          active={activeItem === 'graduation'}
          onClick={handleItemClick}
        >
          <Icon name='graduation' size='tiny' />
          Classes
        </MenuItem>

        <MenuItem
          as={Link}
          to="/pages"
          name='bars'
          active={activeItem === 'bars'}
          onClick={handleItemClick}
        >
          <Icon name='bars' size='tiny' />
          More
        </MenuItem>
      </Menu>
    
  );
};

export default Navigation;