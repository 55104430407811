import React from 'react';
import 'fomantic-ui-css/semantic.min.css';
import Layout from '../Layout';
import {
  GridRow,
  GridColumn,
  Grid,
  Header // Import Header component
} from 'semantic-ui-react';

function ThankYouSocials() {
  return (
    <Layout regularPage={true}>
      <section className="ui container thankyou">
        <Grid verticalAlign='middle' className="section">
            <Header size='large' className="intro-header">Thank you!</Header>
            <GridRow>
              <GridColumn>
                <p>Thank you for your payment. We look forward to dancing with you at the social.</p>
              </GridColumn>
            </GridRow>
          </Grid>
      </section>
    </Layout>
  );
}

export default ThankYouSocials;
