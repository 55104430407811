import React from 'react';
import 'fomantic-ui-css/semantic.min.css';
import Layout from '../Layout';
import {
  GridRow,
  GridColumn,
  Grid,
  Header // Import Header component
} from 'semantic-ui-react';

function ThankYouClasses() {
  return (
    <Layout regularPage={true}>
      <section className="App-breadcrumb home">
        
      </section>
      <section className="ui container thankyou">
        <Grid verticalAlign='middle' className="section">
            <Header size='large' className="intro-header">Thank you!</Header>
            <GridRow>
              <GridColumn>
                <p>Thank you for registering for our classes. We look forward to seeing you on your first day of class.</p>
              </GridColumn>
            </GridRow>
          </Grid>
      </section>
    </Layout>
  );
}

export default ThankYouClasses;
