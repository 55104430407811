import React from 'react';
import '../App.css';
import 'fomantic-ui-css/semantic.min.css';
import Layout from '../Layout';
import {
  Header,
  GridRow,
  GridColumn,
  Grid,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
  CardHeader,
  CardContent,
  CardDescription,
  Card,
  Image,
} from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

const colors = ['red'];

function Family() {
  return (
    <Layout>
      {isMobile ? (
        <></>
      ) : (
        <section className="hero">
          <Image src="../assets/ldc-bg-2022.webp" fluid />
        </section>
      )}
      {!isMobile && (
        <section className="App-breadcrumb">
          <Grid padded>
            {colors.map((color) => (
              <GridRow color={color} key={color}>
                <GridColumn>
                  <Breadcrumb>
                    <BreadcrumbSection as={Link} to="/">Home</BreadcrumbSection>
                    <BreadcrumbDivider />
                    <BreadcrumbSection active>
                      Our Family
                    </BreadcrumbSection>
                  </Breadcrumb>
                </GridColumn>
              </GridRow>
            ))}
          </Grid>
        </section>
      )}
      <section className="ui container family">
        <Grid className="section">
          <GridRow>
            <GridColumn mobile={16} tablet={16} computer={16}>
              {isMobile ? (
                <></>
              ) : (
                <Header size='large'>Our Family</Header>
              )}
              <p>We believe that dance can bring joy, freedom, and improve health which has been proven to improve the quality of life. Through our passion and love for Latin dancing we have the desire to share our knowledge and experience, and bring you that joy and freedom.</p>
              <p>Our dedication and commitment is to make sure you have fun, grow, feel supported and dance. With our undisputed teaching methods, support, care, passion, and charisma, new students go home having learned something new. Our regular students will have had more practise time and get better. Meet new people, make new friends and, most importantly, have fun!</p>
              <p>So what do we do? It is simple. We share with you our knowledge on how to dance Salsa, Bachata, and other Latin dances effectively and beautifully. We are Latin Dance Canada&mdash;we invite you to become part of our family!</p>
            </GridColumn>
          </GridRow>
          <GridRow className="section">
            <GridColumn mobile={16} tablet={8} computer={8} style={{ marginBottom: '2rem' }}>
              <Card centered className="big">
                <Image src="assets/ronald_2012_square.jpeg" wrapped ui={false} />
                <CardContent>
                  <CardHeader>Ronald Martinez</CardHeader>
                  <CardDescription>
                    Director
                  </CardDescription>
                </CardContent>
              </Card>
            </GridColumn>
            <GridColumn mobile={16} tablet={8} computer={8} style={{ marginBottom: '2rem' }}>
              <Card centered className="big">
                <Image src="assets/thomas_2018.jpeg" wrapped ui={false} />
                <CardContent>
                  <CardHeader>Thomas Jono</CardHeader>
                  <CardDescription>
                    Webmaster
                  </CardDescription>
                </CardContent>
              </Card>
            </GridColumn>
          </GridRow>
        </Grid>
      </section>
    </Layout>
  );
  
}

export default Family;
