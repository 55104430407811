import React from 'react';
import 'fomantic-ui-css/semantic.min.css';
import {
  GridRow,
  GridColumn,
  Grid,
  Divider,
  Icon
} from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';

const Footer = () => {
  return (
    <footer>
      <Divider />
      <Grid verticalAlign='middle' className="ui container">
        <GridRow className="sub-menu">
          <GridColumn mobile={16} tablet={8} computer={8} className="social-media">
            <div>
              <a href="https://www.facebook.com/latindancecanada" target="_blank" rel="noreferrer">
                <Icon name='facebook' size='big' color='black' link />
              </a>
              <a href="https://www.instagram.com/latindancecanada/" target="_blank" rel="noreferrer">
                <Icon name='instagram' size='big' color='black' link />
              </a>
            </div>
          </GridColumn>
          <GridColumn mobile={16} tablet={8} computer={8} className="secondary-menu">
            <div className={`ui horizontal ${isMobile ? '' : 'right floated'} list`}>
              <a href="/our-family" className="item">
                Our Family
              </a>
              <a href="/contact" className="item">
                Contact
              </a>
              <a href="/conduct" className="item">
                Conduct
              </a>
            </div>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn mobile={16} tablet={16} computer={16} className="credit">
            <p>&copy; 2024 Latin Dance Canada. All rights reserved.</p>
          </GridColumn>
        </GridRow>
      </Grid>
    </footer>
  );
};

export default Footer;