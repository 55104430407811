// components/Header/Header.js
import React, { useEffect } from 'react';
import Navigation from '../Navigation';
import '../../App.css';

const Header = () => {

  useEffect(() => {
    document.title = 'Latin Dance Canada';
  }, []);

  return (
    <header className="App-header">
      <h1 className="ui header">Latin Dance Canada</h1>
      <Navigation />
    </header>
  );
};

export default Header;
